import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';
import SEO from '../../../components/seo';

export default function RegisterMOFA(props) {
  return (
    <Layout>
      <SEO lang='en' title='#GMIS2022 - MOFA' />
      <RegistrationFrom source='MOFA' page='mofa' imageName='mofa-expo' imageAlt='mofa-expo' />
    </Layout>
  );
}
